<template>
  <div class="login" >
    <van-form @submit="onSubmit" :show-error-message="false" v-if="getMobile">
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号码"
        clearable
        placeholder="请输入手机号码"
        :rules="[
          {
            pattern: /^1[3-9]\d{9}$/,
            required: true,
            message: '请输入手机号码',
          },
        ]"
      />
      <van-field
        v-model="form.sms"
        name="sms"
        label="验证码"
        center
        clearable
        placeholder="请输入验证码"
        :rules="[{ required: true, message: '请输入验证码' }]"
      >
        <template #button>
          <van-button
            size="small"
            :disabled="disabled"
            type="primary"
            @click="getSms"
            >{{ timeText }}</van-button
          >
        </template>
      </van-field>
      <div style="margin: 16px">
        <van-button block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Form, Field, Button, Toast } from "vant";
import { login, getSms, saveByMobile, refreshToken } from "@/api/login";
import { getQueryVariable, checkPhone } from "@/common/utils";
import { wxoffiaccount_auth_url } from "@/common/config";
import { mapActions } from "vuex";
export default {
  name: "login",
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  data() {
    return {
      userInfo: {},
      getMobile: false,
      disabled: false,
      timeText: "获取验证码",
      form: {
        phone: "",
        sms: "",
        smsKey: "",
      },
    };
  },
  computed: {
    code() {
      return getQueryVariable("code");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    init() {
      if (this.code) {
        this.login(this.code);
      } else {
        window.location.href = wxoffiaccount_auth_url;
      }
    },
    async onSubmit() {
      const params = {
        code: this.form.sms,
        key: this.form.smsKey,
        phone: this.form.phone,
      };
      const res = await saveByMobile(params);
      if (res.success) {
        this.refreshToken(res.data.refresh_token);
      }
    },
    async refreshToken(refresh_token) {
      const userInfo = await refreshToken(refresh_token);
      this.userInfo = userInfo;
      this.setUserInfo(userInfo)
      this.$router.push("/");
    },
    async getSms() {
      if (!checkPhone(this.form.phone)) {
        return Toast("请输入正确的手机号码");
      }
      const res = await getSms({
        phone: this.form.phone,
        code: 'uninets'
      });
      this.form.smsKey = res.data.id;
      let s = 60;
      this.timeText = s + "s";
      this.disabled = true;
      const timer = setInterval(() => {
        s--;
        this.timeText = s + "s";
        if (s < 0) {
          this.timeText = "重新获取";
          this.disabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    async login(code) {
      const userInfo = await login(code);
      // console.log(user)
      this.userInfo = userInfo;
      this.setUserInfo(userInfo)
      if (userInfo.user_id === "0") { // 临时用户
        this.getMobile = true;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>