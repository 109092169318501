var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[(_vm.getMobile)?_c('van-form',{attrs:{"show-error-message":false},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"phone","label":"手机号码","clearable":"","placeholder":"请输入手机号码","rules":[
        {
          pattern: /^1[3-9]\d{9}$/,
          required: true,
          message: '请输入手机号码',
        },
      ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"name":"sms","label":"验证码","center":"","clearable":"","placeholder":"请输入验证码","rules":[{ required: true, message: '请输入验证码' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('van-button',{attrs:{"size":"small","disabled":_vm.disabled,"type":"primary"},on:{"click":_vm.getSms}},[_vm._v(_vm._s(_vm.timeText))])]},proxy:true}],null,false,3967358055),model:{value:(_vm.form.sms),callback:function ($$v) {_vm.$set(_vm.form, "sms", $$v)},expression:"form.sms"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("登录")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }